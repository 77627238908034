// Utilities
import { getMedia } from "../utilities/getMedia";
import { getLocale } from "../utilities/getLocale";

const isLarge = getMedia().matches;

const speedoFR = isLarge
  ? "car/speedo_states_FR.json"
  : "car/speedo_states_mobile_FR.json";

const speedoEN = isLarge
  ? "car/speedo_states_EN.json"
  : "car/speedo_states_mobile_EN.json";

const files = {
  fr: {
    car: "car/car_states.json",
    smoke: "car/smoke_loop.json",
    lines: "car/lines_states.json",
    button: speedoFR,
    speedo: speedoFR
  },
  en: {
    car: "car/car_states.json",
    smoke: "car/smoke_loop.json",
    lines: "car/lines_states.json",
    button: speedoEN,
    speedo: speedoEN
  }
};

const options = {
  loop: true
};

export default {
  files: files[getLocale()],
  options
};

// const carFiles = files[getLocale()];
// const options = {
//   loop: true
// };

// export { carFiles, options as carOptions };
