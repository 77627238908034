// Utilities
import { getMedia } from "../utilities/getMedia";
import { getLocale } from "../utilities/getLocale";

const isLarge = getMedia().matches;

const large = {
  fr: {
    process: {
      file: "process/process_presentation_FR.json",
      loop: false
    },
    navigation: {
      file: "process/process_navigation_FR.json",
      loop: false
    }
  },
  en: {
    process: {
      file: "process/process_presentation_EN.json",
      loop: false
    },
    navigation: {
      file: "process/process_navigation_EN.json",
      loop: false
    }
  }
};

const small = {
  fr: {
    process: {
      file: "process/process_mobile_FR.json",
      loop: true
    }
  },
  en: {
    process: {
      file: "process/process_mobile_EN.json",
      loop: true
    }
  }
};

const files = isLarge ? large : small;
const options = { isLarge };

export default { files: files[getLocale()], options };
