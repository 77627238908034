// https://github.com/gaellepasquier/site-web/blob/develop/src/hooks/useMedia.js

export function getMedia(breakpoint = "767px") {
  const query = `(min-width: ${breakpoint})`;

  const mql = window.matchMedia(query);
  // Reload the page when the breakpoint changes
  // So that the right lottie files load
  mql.addListener(() => {
    // mql.addEventListener("change") does not work in Safari.
    window.location.reload();
  });
  return mql;
}
