export function playLottie({ anim, event, name }, segments) {
  // "loopComplete" is when loop is set to true in the anim config

  anim.removeEventListener(event);
  // Clean previous event listeners attached to this animation,
  // to prevent logging multiples events in the console for the same animation.
  // Promises resolve only once.

  const withoutSegments = resolve => {
    anim.play();
    const callback = () => {
      // console.log(`${name}: ${event}`);
      resolve({ anim, event });
    };
    anim.addEventListener(event, callback, { once: true });
  };

  if (!segments) return new Promise(withoutSegments);

  const withSegments = resolve => {
    anim.playSegments(segments, true);
    // forceFlag: If set to false, it will wait until the current segment is complete. If true, it will update values immediately.

    const callback = () => {
      // console.log(`${name}: ${event}`);
      resolve({ anim, event, start: segments[0], end: segments[1] });
    };
    anim.addEventListener(event, callback, { once: true });
  };

  return new Promise(withSegments);
}
