// Packages
import lottie from "lottie-web";

// Utilities
import { getTimestamp } from "../utilities/getTimestamp";
import { buildAssetPath } from "../utilities/buildAssetPath";
import { addDebug } from "../utilities/addDebug";

export function mountLottie(config = {}) {
  const defaults = {
    container: document.querySelector("body"), // the dom element that will contain the animation
    renderer: "svg",
    loop: true,
    autoplay: false,
    path: buildAssetPath(config.file),
    rendererSettings: {
      className: config.name
    }
  };

  const anim = lottie.loadAnimation({ ...defaults, ...config });
  anim.setSubframe(false);
  if (config.debug) addDebug(anim, config.name);

  return new Promise((resolve, reject) => {
    anim.addEventListener("DOMLoaded", () => {
      console.log(`✅ Added to HTML: ${anim.fileName}`, getTimestamp());
      resolve({
        anim,
        name: config.name,
        hotspot: config.container.querySelector("svg > g"),
        event: anim.loop ? "loopComplete" : "complete"
      });
    });

    anim.addEventListener("data_failed", () =>
      reject(new Error(`❌ Failed: ${anim.fileName}`))
    );
  });
}
