// Packages
import anime from "animejs/lib/anime.es.js";

export function animateCar(targets) {
  anime({
    targets,
    translateX: 40,
    autoplay: true,
    duration: 4000,
    easing: "easeInOutCubic",
    direction: "alternate",
    loop: true
  });
}
