// Lottie
import { mountLottie } from "../lottie/mountLottie";
import { playLottie } from "../lottie/playLottie";
import { syncLottie } from "../lottie/syncLottie";

// Animations
import { animateCar } from "../animations/car";

// Containers
const carContainer = document.getElementById("car");
const smokeContainer = document.getElementById("smoke");
const linesContainer = document.getElementById("lines");
const buttonContainer = document.getElementById("button");
const speedometerContainer = document.getElementById("speedometer");

// Global variable
let canRestart = true;
let clicked = false;

export function playCar({ files, options }) {
  const car = mountLottie({
    container: carContainer,
    file: files.car,
    loop: options.loop,
    name: "car"
  });

  const smoke = mountLottie({
    container: smokeContainer,
    file: files.smoke,
    loop: options.loop,
    name: "smoke"
  });

  const lines = mountLottie({
    container: linesContainer,
    file: files.lines,
    loop: options.loop,
    name: "lines"
  });

  const button = mountLottie({
    container: buttonContainer,
    file: files.button,
    name: "button"
  });

  const speedometer = mountLottie({
    container: speedometerContainer,
    file: files.speedo,
    name: "speedometer"
  });

  // Car anim
  syncLottie([button, speedometer, car, smoke, lines]).then(data => {
    // Animate car once all lottie are loaded
    animateCar([carContainer, smokeContainer]);

    const [button, speedometer, car, smoke, lines] = data;
    const buttonHotspot = button.hotspot;
    buttonHotspot.classList.add("pointer");

    const speedometerIdleSegment = [0, 359];
    const speedometerBoostSegment = [360, 719];
    const buttonIdleSegment = [720, 1079];
    const buttonHoverSegment = [1080, 1139];
    const buttonBoostSegment = [1140, 1499];
    const carIdleSegment = [0, 120];
    const carBoostSegment = [120, 480];
    const smokeIdleSegment = [0, 180];
    const linesIdleSegment = [0, 60];
    const linesBoostSegment = [60, 420];

    // Init animations
    playLottie(button, buttonIdleSegment);
    playLottie(speedometer, speedometerIdleSegment);
    playLottie(car, carIdleSegment);
    playLottie(smoke, smokeIdleSegment);
    playLottie(lines, linesIdleSegment);

    buttonHotspot.addEventListener("mouseenter", () => {
      // console.log("mouseenter");
      if (canRestart) {
        playLottie(button, buttonHoverSegment).then(data => {
          data.anim.goToAndStop(data.end - data.start, true);
        });
      }
    });

    buttonHotspot.addEventListener("mouseleave", () => {
      // console.log("mouseleave");
      if (canRestart) {
        playLottie(button, buttonIdleSegment);
      }
      // playSegmentsAndStop(anim, buttonHoverSegment);
    });

    buttonHotspot.addEventListener("click", () => {
      canRestart = false;
      // console.log("clicked");
      const resetVariables = () => {
        canRestart = true;
        clicked = false;
      };

      if (!clicked) {
        clicked = true;
        // Smokes
        smoke.anim.goToAndStop(0);

        // Button
        playLottie(button, buttonBoostSegment).then(() => {
          playLottie(button, buttonIdleSegment);
          resetVariables();
        });

        // Speedometer
        playLottie(speedometer, speedometerBoostSegment).then(() => {
          playLottie(speedometer, speedometerIdleSegment);
          resetVariables();
        });

        // Speedometer
        playLottie(car, carBoostSegment).then(() => {
          playLottie(smoke, smokeIdleSegment);
          playLottie(car, carIdleSegment);
          resetVariables();
        });

        // Lines
        playLottie(lines, linesBoostSegment).then(() => {
          playLottie(lines, linesIdleSegment);
        });
      }
    });
  });
}
