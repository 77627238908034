// Packages
import anime from "animejs/lib/anime.es.js";

// Elements
const buttonTop = document.querySelector("#open");
const mobileButtonTop = document.querySelector("#mobileOpen");
const close = document.querySelector("#close-contact");
const body = document.querySelector("body");

const emailBottom = document.querySelector("#bottomEmail");
const buttonBottom = document.querySelector("#buttonBottomContainer");

export function animateContactTop() {
  const duration = 1800;

  function slideDown() {
    anime({
      targets: body,
      translateY: 240,
      duration,
      easing: "easeInOutCubic"
    });
  }

  function slideUp() {
    anime({
      targets: body,
      translateY: 0,
      duration,
      easing: "easeInOutCubic"
    });
  }

  buttonTop.addEventListener("click", slideDown);
  mobileButtonTop.addEventListener("click", slideDown);
  close.addEventListener("click", slideUp);
}

export function animateContactBottom() {
  buttonBottom.addEventListener("click", e => {
    const btn = e.currentTarget;
    btn.classList.add("fadeout");

    setTimeout(() => {
      btn.classList.add("hidden");
    }, 1000);

    setTimeout(() => {
      emailBottom.classList.remove("hidden");
      emailBottom.classList.remove("pointer-none");
      emailBottom.classList.add("fadein");
    }, 1000);
  });
}
