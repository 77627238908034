// Utilities
import { getLocale } from "../utilities/getLocale";

const files = {
  fr: {
    button: "contact/btn_contact_FR.json"
  },
  en: {
    button: "contact/btn_contact_EN.json"
  }
};

const options = {
  loop: true
};

export default {
  files: files[getLocale()],
  options
};
