// Lottie
import { mountLottie } from "../lottie/mountLottie";
import { playLottie } from "../lottie/playLottie";

// Containers
const devicesContainer = document.getElementById("devicesContainer");

export function playDevices({ loop }) {
  mountLottie({
    container: devicesContainer,
    file: "devices/devices.json",
    loop,
    name: "devices"
  }).then(data => {
    playLottie(data);
  });
}
