// Packages
import throttle from "lodash/throttle";

// Selectors
const debug = document.querySelector(".debugger");
const container = document.querySelector(".debuggerContainer");
const close = document.querySelector(".debuggerHeader");

export function addDebug(anim, name = anim.fileName) {
  const displayDebugInfo = parentEl => {
    const throttledFrame = throttle(function() {
      parentEl.innerHTML = `
      <h3>${name}</h3>
      <p style="color: ${
        !anim.isPaused ? "green" : "red"
      }">Playing: ${!anim.isPaused}<p/>
      <p>Current frame: ${anim.renderer.renderedFrame}</p>
      <p>Segment length: ${anim.getDuration(true)} frames<p/>
      <p>Looping: ${anim.loop}<p/>
      <p>Play direction: ${anim.playDirection}<p/>
      <p>Speed: ${anim.playSpeed}<p/>
      <p>Frame Rate: ${anim.frameRate}<p/>
      `;
    }, 50);
    anim.addEventListener("enterFrame", throttledFrame);
  };

  const addDebuggerContainer = () => {
    const container = document.createElement("div");
    container.classList.add("debug-anim");
    debug.appendChild(container);
    debug.appendChild(container);
    displayDebugInfo(container);
  };

  const closeDebugger = () => {
    container.remove();
  };

  close.removeEventListener("click", closeDebugger);
  close.addEventListener("click", closeDebugger);

  anim.addEventListener("config_ready", addDebuggerContainer);
}
