// Lottie
import { mountLottie } from "../lottie/mountLottie";
import { playLottie } from "../lottie/playLottie";
import { syncLottie } from "../lottie/syncLottie";

// Containers
const processContainer = document.getElementById("process");
const navigationContainer = document.getElementById("navigation");
const navigationHotspots = document.querySelectorAll(".hotspots .hotspot");

export function playSmallProcess(process) {
  mountLottie({
    container: processContainer,
    file: process.file,
    loop: process.loop,
    name: "process"
  }).then(data => playLottie(data));
}

export function playProcess({ files, options }) {
  if (!options.isLarge) return playSmallProcess(files.process);

  const process = mountLottie({
    container: processContainer,
    file: files.process.file,
    loop: files.process.loop,
    name: "process"
  });

  const navigation = mountLottie({
    container: navigationContainer,
    file: files.navigation.file,
    loop: files.navigation.loop,
    name: "navigation"
  });

  // Global variables
  let currentStep = 0;

  syncLottie([process, navigation]).then(data => {
    const [process, navigation] = data;

    const processSegment1 = [0, 329];
    const processSegment2 = [330, 629];
    const processSegment3 = [630, 929];
    const processSegment4 = [930, 1229];

    // FOREWARD
    const navSegment1to2 = [0, 119];
    const navSegment2to3 = [120, 239];
    const navSegment3to4 = [240, 359];
    const navSegment1to3 = [360, 479];
    const navSegment1to4 = [480, 599];
    const navSegment2to4 = [600, 719];

    // BACKWARD
    const navSegment4to3 = [720, 839];
    const navSegment3to2 = [840, 959];
    const navSegment2to1 = [960, 1079];
    const navSegment4to2 = [1080, 1199];
    const navSegment4to1 = [1200, 1319];
    const navSegment3to1 = [1320, 1439];

    // Callbacks
    function transitionLoop(navSegment, processSegment, newCurrentStep) {
      currentStep = newCurrentStep;

      processContainer.classList.add("fadein-anim");

      playLottie(navigation, navSegment).then(() => {
        processContainer.classList.remove("fadein-anim");
      });
      playLottie(process, processSegment).then(() => {
        playLoop();
      });
    }

    function playOnClick(event) {
      const clicked = event.target.dataset.step;
      // console.log("currentStep", currentStep, "| clicked", clicked);

      if (clicked == currentStep) return;

      // FOREWARD
      if (currentStep == 1 && clicked == 2) {
        transitionLoop(navSegment1to2, processSegment2, 2);
      }
      if (currentStep == 1 && clicked == 3) {
        transitionLoop(navSegment1to3, processSegment3, 3);
      }
      if (currentStep == 1 && clicked == 4) {
        transitionLoop(navSegment1to4, processSegment4, 4);
      }
      if (currentStep == 2 && clicked == 3) {
        transitionLoop(navSegment2to3, processSegment3, 3);
      }
      if (currentStep == 2 && clicked == 4) {
        transitionLoop(navSegment2to4, processSegment4, 4);
      }
      if (currentStep == 3 && clicked == 4) {
        transitionLoop(navSegment3to4, processSegment4, 4);
      }

      // BACKWARD
      if (currentStep == 4 && clicked == 1) {
        transitionLoop(navSegment4to1, processSegment1, 1);
      }
      if (currentStep == 4 && clicked == 2) {
        transitionLoop(navSegment4to2, processSegment2, 2);
      }
      if (currentStep == 4 && clicked == 3) {
        transitionLoop(navSegment4to3, processSegment3, 3);
      }
      if (currentStep == 3 && clicked == 2) {
        transitionLoop(navSegment3to2, processSegment2, 2);
      }
      if (currentStep == 3 && clicked == 1) {
        transitionLoop(navSegment3to1, processSegment1, 1);
      }
      if (currentStep == 2 && clicked == 1) {
        transitionLoop(navSegment2to1, processSegment1, 1);
      }
    }

    navigationHotspots.forEach(el => el.addEventListener("click", playOnClick));

    function syncAnims(data, navSegment, processSegment, animStep) {
      // console.log("Current step at run: ", currentStep);
      if (currentStep >= animStep) return Promise.resolve(data);

      currentStep += 1;
      // console.log("Current step after +1: ", currentStep);

      // When a new play segment runs,
      // the event listener of the former anim never gets a change to trigger.
      // No need to cancel the previous one.
      playLottie(navigation, navSegment);

      // Returns a promise that will decide when the next .then triggers.
      return playLottie(data, processSegment);
    }

    function playLoop() {
      // console.log("Loop resets");
      syncAnims(process, [0, 1], processSegment1, 1)
        .then(data => syncAnims(data, navSegment1to2, processSegment2, 2))
        .then(data => syncAnims(data, navSegment2to3, processSegment3, 3))
        .then(data => syncAnims(data, navSegment3to4, processSegment4, 4))
        .then(() => playLottie(navigation, navSegment4to1))
        .then(() => {
          currentStep = 0;
          // console.log(currentStep);
          playLoop();
        });
    }

    playLoop();
  });
}
