// Lottie
import { mountLottie } from "../lottie/mountLottie";
import { playLottie } from "../lottie/playLottie";
import { syncLottie } from "../lottie/syncLottie";

// Containers
const squareContainer = document.getElementById("square");
const circleContainer = document.getElementById("circle");
const triangleContainer = document.getElementById("triangle");
const codeContainer = document.getElementById("code");
const responsiveContainer = document.getElementById("responsive");

export function playIcons({ loop }) {
  const square = mountLottie({
    container: squareContainer,
    file: "icons/square.json",
    loop,
    name: "square"
  });
  const circle = mountLottie({
    container: circleContainer,
    file: "icons/circle.json",
    loop,
    name: "circle"
  });
  const triangle = mountLottie({
    container: triangleContainer,
    file: "icons/triangle.json",
    loop,
    name: "triangle"
  });
  const code = mountLottie({
    container: codeContainer,
    file: "icons/code.json",
    loop,
    name: "code"
  });
  const responsive = mountLottie({
    container: responsiveContainer,
    file: "icons/responsive.json",
    loop,
    name: "responsive"
  });
  syncLottie([square, circle, triangle, code, responsive]).then(data => {
    data.forEach(anim => playLottie(anim));
  });
}
