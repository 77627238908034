export function inView(element, callback, config = {}) {
  const options = {
    root: null,
    // Defaults to the browser viewport if not specified or if null.
    rootMargin: "0px",
    threshold: 0
  };
  const target = document.querySelector(element);

  function executor(entries, observer) {
    // console.log(observer);
    const isInView = entries[0].isIntersecting;
    if (isInView) {
      callback();
      // Stops the observer from firing callback() everytime time the target enters the viewport
      observer.disconnect();
    }
  }

  let observer = new IntersectionObserver(executor, { ...options, ...config });

  observer.observe(target);
}
