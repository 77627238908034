// Lottie
import { mountLottie } from "../lottie/mountLottie";
import { playLottie } from "../lottie/playLottie";
import { playLottieOnce } from "../lottie/playLottieOnce";

// Containers
const buttonContainer = document.getElementById("buttonBottomContainer");

export function playContact({ files, options }) {
  const active = [0, 60];
  const idleSegment = [60, 300];
  const fullSegment = [0, 300];

  mountLottie({
    container: buttonContainer,
    file: files.button,
    loop: options.loop,
    name: "contact"
  }).then(data => {
    playLottie(data, fullSegment);

    data.hotspot.addEventListener("mouseenter", () => {
      playLottieOnce(data, active);
    });

    data.hotspot.addEventListener("mouseleave", () => {
      playLottieOnce(data, idleSegment).then(() =>
        playLottie(data, fullSegment)
      );
    });
  });
}
