// Packages
import Flickity from "flickity";

// https://flickity.metafizzy.co/options.html
const sliderContainer = document.querySelector("#flickitySlider");

export function initSlider() {
  new Flickity(sliderContainer, {
    cellAlign: "center",
    prevNextButtons: false,
    pageDots: false,
    draggable: true,
    freeScroll: false,
    contain: true,
    // Forever scrolling option
    wrapAround: true,
    autoPlay: 5000,
    lazyLoad: true
  });
}

// Create a function that loads the 3 mobile assets
// Another that loads the the other ones
// And a function combining these two

export function getSliderHTML() {
  const template = sliderContainer.querySelector("template");
  const slidesHTML = template.innerHTML;
  template.remove();
  return slidesHTML;
}

export function loadSliderHTML(html, callback) {
  sliderContainer.innerHTML = html;
  callback();
}
