// Packages
// import dotenv from "dotenv";
// dotenv.config();

// Sequences
import { playCar } from "./js/sequences/car";
import { playProcess } from "./js/sequences/process";
import { playIcons } from "./js/sequences/icons";
import { playDevices } from "./js/sequences/devices";
import { playContact } from "./js/sequences/contact";

// Animations
import {
  animateContactTop,
  animateContactBottom
} from "./js/animations/contact";
import {
  initSlider,
  getSliderHTML,
  loadSliderHTML
} from "./js/animations/slider";

// Utilities
import { inView } from "./js/utilities/inView";
import { getMedia } from "./js/utilities/getMedia";

// Files
import carConfig from "./js/config/car";
import processConfig from "./js/config/process";
import contactConfig from "./js/config/contact";

const isLarge = getMedia().matches;

playCar(carConfig);
inView("#features-section", () => playIcons({ loop: true }));
inView("#features-section", () => playDevices({ loop: true }));
inView("#process-section", () => playProcess(processConfig));
inView("#referrals-section", () => playContact(contactConfig));

window.addEventListener("DOMContentLoaded", () => {
  const slidesHTML = getSliderHTML();
  inView("#weight-section", () =>
    loadSliderHTML(slidesHTML, () => {
      if (isLarge) initSlider();
    })
  );
  animateContactTop();
  animateContactBottom();
});
